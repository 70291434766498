import { SimilarProperties } from '../Components/SimilarProperties';
import { PropertyVideoGallery } from '../Components/PropertyVideos';
import { PhotoGalleryGallery } from '../Components/PhotoGallery';
import '../Style/property.css';

export const Property = () => {

    return <>
        <div className='container property-container mt-5 box-shadow common-padding'>
            <div className='row'>
                <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12'>
                    <img className='property-image' src="imgs/projects/p1.jpg" />
                </div>
                <div className='col-xl-8 col-md-8 col-sm-12 col-xs-12 property-details'>
                    <h1>Guruji Swarna Vihar -1</h1>
                    <hr />

                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <ul>
                                <li className='li-icon'>
                                    <span className='prop-icon'> <i className="fa fa-map-marker" aria-hidden="true"></i></span>
                                </li>
                                <li>
                                    <span className='prop-label'>Location</span>
                                    <span className='prop-value'>Nawada Housing Complex, Dwarka Mod, Uttam Nagar, New Delhi-110056</span>
                                </li>
                            </ul>

                        </div>
                        <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-3'>
                            <ul>
                                <li className='li-icon'> <span className='prop-icon'> <i className="fa fa-building" aria-hidden="true"></i></span></li>
                                <li>
                                    <span className='prop-label'> Property Type</span>
                                    <span className='prop-value'>Residential</span>
                                </li>
                            </ul>
                        </div>

                        <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-3'>
                            <ul>
                                <li className='li-icon'> <span className='prop-icon'> <i className="fas fa-chart-area" aria-hidden="true"></i></span></li>
                                <li>
                                    <span className='prop-label'>Area</span>
                                    <span className='prop-value'>120 sq yards</span>
                                </li>
                            </ul>
                        </div>


                        <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-3'>
                            <ul>
                                <li className='li-icon'> <span className='prop-icon'> <i className="fa fa-building" aria-hidden="true"></i></span></li>
                                <li>
                                    <span className='prop-label'> No. Of Apartments.</span>
                                    <span className='prop-value'>1</span>
                                </li>
                            </ul>
                        </div>

                        <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-3'>
                            <ul>
                                <li className='li-icon'><span className='prop-icon'> <i className="fa-solid fa-cake-candles" aria-hidden="true"></i></span></li>
                                <li>
                                    <span className='prop-label'>Property Age</span>
                                    <span className='prop-value'>2.0 Years</span>
                                </li>
                            </ul>
                        </div>

                        <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-3'>
                            <ul>
                                <li className='li-icon'><span className='prop-icon'> <i className="fa-solid fa-stairs" aria-hidden="true"></i></span> </li>
                                <li>
                                    <span className='prop-label'>Total Floors</span>
                                    <span className='prop-value'>3</span>
                                </li>
                            </ul>
                        </div>

                        <div className='col-xl-4 col-md-4 col-sm-12 col-xs-12 mb-3'>
                            <ul>
                                <li className='li-icon'><span className='prop-icon'> <i className="fa-solid fa-indian-rupee-sign" aria-hidden="true"></i></span> </li>
                                <li>
                                    <span className='prop-label'>Price</span>
                                    <span className='prop-value'>78.00 LKH</span>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <a href="#" className="btn btn-primary">Enquire About This Property <i className="fa fa-angle-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PhotoGalleryGallery />
        <PropertyVideoGallery />

        <div className='container features mt-5'>
            <div className='row mb-3'>
                <div className='col-12'>
                    <h2>Features</h2>
                </div>
            </div>
            <div className='box-shadow common-padding'>
                <div className='row'>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-hand-holding-droplet"></i></span>
                        <span> All Sanitary Items</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-box"></i></span>
                        <span>  Wooden Almirah</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-square-parking"></i></span>
                        <span>  Car Parking</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-walkie-talkie"></i></span>
                        <span> Intercom</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-elevator"></i></span>
                        <span> Modular Kitchen</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-regular fa-image"></i></span>
                        <span> Lift</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fas fa-chart-area"></i></span>
                        <span> Customize Wallpaper & Textured</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-torii-gate"></i></span>
                        <span> Wall Cielling With Concield Light</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-house-chimney"></i></span>
                        <span> Gated Community</span>
                    </div>
                </div>
            </div>
        </div>

        <div className='container features mt-5'>
            <div className='row mb-3'>
                <div className='col-12'>
                    <h2>Why should you consider this property?</h2>
                </div>
            </div>
            <div className='box-shadow common-padding'>
                <div className='row'>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-glasses"></i></span>
                        <span> Luxuarious</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-square-parking"></i></span>
                        <span>Free Parking on premises</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-paw"></i></span>
                        <span> Pet Friendly</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-torii-gate"></i></span>
                        <span> Gated Locality</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-bowl-food"></i></span>
                        <span> Food</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-cart-shopping"></i></span>
                        <span> Grocery</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-stethoscope"></i></span>
                        <span> Medicine</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-truck-field"></i></span>
                        <span> Pet Supplies</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-pepper-hot"></i></span>
                        <span> Fruits & Vegetables</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-shield-halved"></i></span>
                        <span> Camera and Security Available</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-map-pin"></i></span>
                        <span> Nearby Dwarka Mod</span>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-2 col-xs-12 mb-3'>
                        <span className='feature-icon'> <i className="fa-solid fa-train"></i></span>
                        <span> Nawada Metro Stations</span>
                    </div>
                </div>
            </div>
        </div>
        <SimilarProperties />
    </>

}