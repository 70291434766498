import './PropertyVideos.css'

export const PropertyVideoGallery = () => {

    return <>

        <div className='container mt-5'>
            <div className='row'>
                <div className='col-md-8'><h2>Video Gallery</h2></div>
            </div>

            <div className='row mt-3'>
                <div className='col-xl-2 col-md-2 col-sm-6 col-6 mb-3'>
                    <div className='PropertyVideoGallery '>
                        <a href='#' data-bs-toggle="modal" data-bs-target="#youtubeModal" >
                            <img src="imgs/projects/1.jpg" />
                            <div className='overlay'></div>
                            <div className='play-button'>
                                <div className='play-bg'>
                                    <i className="fa-solid fa-play"></i>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className='col-xl-2 col-md-2 col-sm-6 col-6 mb-3'>
                    <div className='PropertyVideoGallery '>
                        <a href='#' data-bs-toggle="modal" data-bs-target="#youtubeModal" >
                            <img src="imgs/projects/2.jpg" />
                            <div className='overlay'></div>
                            <div className='play-button'>
                                <div className='play-bg'>
                                    <i className="fa-solid fa-play"></i>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className='col-xl-2 col-md-2 col-sm-6 col-6 mb-3'>
                    <div className='PropertyVideoGallery '>
                        <a href='#' data-bs-toggle="modal" data-bs-target="#youtubeModal" >
                            <img src="imgs/projects/3.jpg" />
                            <div className='overlay'></div>
                            <div className='play-button'>
                                <div className='play-bg'>
                                    <i className="fa-solid fa-play"></i>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className='col-xl-2 col-md-2 col-sm-6 col-6 mb-3'>
                    <div className='PropertyVideoGallery '>
                        <a href='#' data-bs-toggle="modal" data-bs-target="#youtubeModal" >
                            <img src="imgs/projects/4.jpg" />
                            <div className='overlay'></div>
                            <div className='play-button'>
                                <div className='play-bg'>
                                    <i className="fa-solid fa-play"></i>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>

        <div className="modal fade youtubeModal" id="youtubeModal" tabindex="-1" aria-labelledby="youtubeModal"
            aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <iframe width="100%" height="600px"
                            src="https://www.youtube.com/embed/3sDcizq4gmg?si=Caudg7jxEG7Eg-mL" title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>

                    </div>
                </div>
            </div>
        </div>
    </>
}