import './WhyUs.css';

export const WhyUs = () => {

    return <>
        <div className='container why-us display-Card mt-5 box-shadow common-padding'>
            <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3 right-border'>
                    <div className='row'>
                        <div className="col-md-12">
                            <h2>
                                <i class="fa fa-star" aria-hidden="true">
                                    <span>5</span>
                                </i>
                                Why Us</h2>
                        </div>
                        <div className='col-md-12'>
                            <p>
                                We values the hardship of their clients and offers highest quality construction services.
                                We
                                strive for the best customer service possible. Our staff always returns phone calls
                                within
                                the same business day and time. Our estimates are measured and delivered with the latest
                                in
                                software technologies. All of our projects are LIFETIME guaranteed and certificates are
                                delivered upon completion.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3'>
                    <div className='row'>
                        <div className="col-md-12">
                            <h2>
                                <i class="fas fa-hand-peace"></i>
                                What We Offer</h2>
                        </div>
                        <div className='col-md-12'>
                            <p>
                                We offers peace of mind and stress-free turnkey construction solution to any kind of residential,
                                commercial or interior requirements with highest level of ethics and transparency.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}