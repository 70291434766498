import './OurProjects.css';

export const OurProjects = () => {

    return <>
        <div className='container OurProjects mt-5 common-padding'>
            <div className='row'>
                <div className='col-md-8'>
                    <h2 className='projects-title'>Our Projects</h2>
                </div>
                <div className='col-md-4 text-right'>
                    <a href='#' className='btn btn-primary'>View More
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
            <div className='row'>
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                    <div className='project-content box-shadow'>
                        <a className='image-link'>
                            <img src="imgs/projects/p1.jpg" />
                            <div className='overlay'></div>
                        </a>
                        <h2 className='border-bottom project-name'> Guruji Swarna Vihar -1</h2>
                        <ul>
                            <li>
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <span>Uttam Nagar, New Delhi</span>
                            </li>
                            <li>
                                <i class="fa fa-building" aria-hidden="true"></i>
                                <span>Residential</span>
                            </li>

                            <li>
                                <i class="fa fa-bath" aria-hidden="true"></i> <span>4 BT</span>
                            </li>
                            <li>
                                <i class="fa fa-bed" aria-hidden="true"></i> <span>4 BD</span>
                            </li>
                            <li>
                                <i class="fas fa-chart-area" aria-hidden="true"></i>
                                <span>120 sq yards</span>
                            </li>
                            <li>
                                <i class="fa fa-inr" aria-hidden="true"></i> <span>78.00 LKH</span>
                            </li>
                        </ul>
                        <a href='#' className='btn btn-primary view-more'>View</a>
                    </div>
                </div>


                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                    <div className='project-content box-shadow'>
                        <a className='image-link'>
                            <img src="imgs/projects/p2.jpeg" />
                            <div className='overlay'></div>
                        </a>
                        <h2 className='border-bottom project-name'> Gaur Green City</h2>
                        <ul>
                            <li>
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <span>Uttam Nagar, New Delhi</span>
                            </li>
                            <li>
                                <i class="fa fa-building" aria-hidden="true"></i>
                                <span>Residential</span>
                            </li>

                            <li>
                                <i class="fa fa-bath" aria-hidden="true"></i> <span>4 BT</span>
                            </li>
                            <li>
                                <i class="fa fa-bed" aria-hidden="true"></i> <span>4 BD</span>
                            </li>
                            <li>
                                <i class="fas fa-chart-area" aria-hidden="true"></i>
                                <span>120 sq yards</span>
                            </li>
                            <li>
                                <i class="fa fa-inr" aria-hidden="true"></i> <span>78.00 LKH</span>
                            </li>
                        </ul>
                        <a href='#' className='btn btn-primary view-more'>View</a>
                    </div>
                </div>


                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                    <div className='project-content box-shadow'>
                        <a className='image-link'>
                            <img src="imgs/projects/p3.jpg" />
                            <div className='overlay'></div>
                        </a>
                        <h2 className='border-bottom project-name'> Metrotech Builder Floors</h2>
                        <ul>
                            <li>
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <span>Uttam Nagar, New Delhi</span>
                            </li>
                            <li>
                                <i class="fa fa-building" aria-hidden="true"></i>
                                <span>Residential</span>
                            </li>

                            <li>
                                <i class="fa fa-bath" aria-hidden="true"></i> <span>4 BT</span>
                            </li>
                            <li>
                                <i class="fa fa-bed" aria-hidden="true"></i> <span>4 BD</span>
                            </li>
                            <li>
                                <i class="fas fa-chart-area" aria-hidden="true"></i>
                                <span>120 sq yards</span>
                            </li>
                            <li>
                                <i class="fa fa-inr" aria-hidden="true"></i> <span>78.00 LKH</span>
                            </li>
                        </ul>
                        <a href='#' className='btn btn-primary view-more'>View</a>
                    </div>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                    <div className='project-content box-shadow'>
                        <a className='image-link'>
                            <img src="imgs/projects/p4.jpeg" />
                            <div className='overlay'></div>
                        </a>
                        <h2 className='border-bottom project-name'> DDA Golf View Apartments</h2>
                        <ul>
                            <li>
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <span>Uttam Nagar, New Delhi</span>
                            </li>
                            <li>
                                <i class="fa fa-building" aria-hidden="true"></i>
                                <span>Residential</span>
                            </li>

                            <li>
                                <i class="fa fa-bath" aria-hidden="true"></i> <span>4 BT</span>
                            </li>
                            <li>
                                <i class="fa fa-bed" aria-hidden="true"></i> <span>4 BD</span>
                            </li>
                            <li>
                                <i class="fas fa-chart-area" aria-hidden="true"></i>
                                <span>120 sq yards</span>
                            </li>
                            <li>
                                <i class="fa fa-inr" aria-hidden="true"></i> <span>78.00 LKH</span>
                            </li>
                        </ul>
                        <a href='#' className='btn btn-primary view-more'>View</a>
                    </div>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                    <div className='project-content box-shadow'>
                        <a className='image-link'>
                            <img src="imgs/projects/p5.jpeg" />
                            <div className='overlay'></div>
                        </a>
                        <h2 className='border-bottom project-name'> Pride World City</h2>
                        <ul>
                            <li>
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <span>Uttam Nagar, New Delhi</span>
                            </li>
                            <li>
                                <i class="fa fa-building" aria-hidden="true"></i>
                                <span>Residential</span>
                            </li>

                            <li>
                                <i class="fa fa-bath" aria-hidden="true"></i> <span>4 BT</span>
                            </li>
                            <li>
                                <i class="fa fa-bed" aria-hidden="true"></i> <span>4 BD</span>
                            </li>
                            <li>
                                <i class="fas fa-chart-area" aria-hidden="true"></i>
                                <span>120 sq yards</span>
                            </li>
                            <li>
                                <i class="fa fa-inr" aria-hidden="true"></i> <span>78.00 LKH</span>
                            </li>
                        </ul>
                        <a href='#' className='btn btn-primary view-more'>View</a>
                    </div>
                </div>
            </div>
        </div>
    </>
}