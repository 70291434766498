import './OurServices.css';

export const OurServices = () => {
    return <>
        <div className='container display-Card mt-5 box-shadow common-padding'>
            <div className='row'>
                <div className='col-md-12'>
                    <h2>
                        <i class="fa fa-cogs" aria-hidden="true"></i>
                        Our Services</h2>
                </div>
                <div className='col-md-12'>
                    <h2 className='sub-title'>Unique and Quality Service Makes Clients Happy</h2>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-xl-2 col-md-3 col-sm-6 col-6 mt-3'>
                    <a href="#" className='display-href'>
                        <img src="imgs/services/6.jpg" />
                        <div className="overlay"></div>
                    </a>
                    <a href='#' className='display-href-link'>
                        Purchase and Sales
                    </a>
                </div>

                <div className='col-xl-2 col-md-3 col-sm-6 col-6 mt-3'>
                    <a href="#" className='display-href'>
                        <img src="imgs/services/5.jpg" />
                        <div className="overlay"></div>
                    </a>
                    <a href='#' className='display-href-link'>
                        Luxury Home Builders
                    </a>
                </div>

                <div className='col-xl-2 col-md-3 col-sm-6 col-6 mt-3'>
                    <a href="#" className='display-href'>
                        <img src="imgs/services/7.webp" />
                        <div className="overlay"></div>
                    </a>
                    <a href='#' className='display-href-link'>
                        Construction Services
                    </a>
                </div>

                <div className='col-xl-2 col-md-3 col-sm-6 col-6 mt-3'>
                    <a href="#" className='display-href'>
                        <img src="imgs/services/3.webp" />
                        <div className="overlay"></div>
                    </a>
                    <a href='#' className='display-href-link'>
                        Project Planning
                    </a>
                </div>

                <div className='col-xl-2 col-md-3 col-sm-6 col-6 mt-3'>
                    <a href="#" className='display-href'>
                        <img src="imgs/services/4.jpg" />
                        <div className="overlay"></div>
                    </a>
                    <a href='#' className='display-href-link'>
                        Interior Construction & Design
                    </a>
                </div>

                <div className='col-xl-2 col-md-3 col-sm-6 col-6 mt-3'>
                    <a href="#" className='display-href'>
                        <img src="imgs/services/1.webp" />
                        <div className="overlay"></div>
                    </a>
                    <a href='#' className='display-href-link'>
                        Renovation and Addition
                    </a>
                </div>
                
                <div className='col-xl-2 col-md-3 col-sm-6 col-6 mt-3'>
                    <a href="#" className='display-href'>
                        <img src="imgs/services/2.webp" />
                        <div className="overlay"></div>
                    </a>
                    <a href='#' className='display-href-link'>
                        Design-Build Services
                    </a>
                </div>
            </div>
        </div>
    </>
}