import './Testimonials.css';

export const Testimonials = () => {
    return <>
        <div className='testimonials container mt-5'>

            <div className='row mb-3' >
                <div className='col-md-12'>
                    <h2>Testimonials</h2>
                </div>
            </div>

            <div className='row'>
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <div className='testimonials-container box-shadow common-padding'>

                        <div className='row mb-3'>
                            <div className='col-md-12 text-center'>
                                <img src="imgs/a2.png" />
                            </div>
                            <div class="col-md-12 user-details">
                                <span>Mr. Joginder Pal</span>
                                <span>West Delhi</span>
                                <span>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                            </div>

                            <div className='col-md-12'>
                                <hr></hr>
                            </div>
                            <div className='col-md-12'>
                                <p class="content">
                                    <i class="fas fa-quote-left"></i>
                                    It has been a great experience dealing with professionals who have structured and
                                    handled
                                    the entire Home making process beautifully &amp; made it a smooth experience for us Owners.
                                    <i class="fas fa-quote-right"></i>

                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <div className='testimonials-container box-shadow common-padding'>
                        <div className='row mb-3'>
                            <div className='col-md-12  text-center'>
                                <img src="imgs/a1.png" />
                            </div>
                            <div class="col-md-12 user-details">
                                <span>Srabani</span>
                                <span>Dwarka Mod, New Delhi</span>
                                <span>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                            </div>
                            <div className='col-md-12'>
                                <hr></hr>
                            </div>
                            <div className='col-md-12'>
                                <p class="content">
                                    <i class="fas fa-quote-left"></i>
                                    The team in Guruji Infratech are professional,
                                    know their job and work on definite timelines.
                                    They manage the project in a manner where
                                    they make sure that we are equally involved in each step of development.
                                    Very flexible and patient listeners.
                                    <i class="fas fa-quote-right"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <div className='testimonials-container box-shadow common-padding'>
                        <div className='row mb-3'>
                            <div className='col-md-12  text-center'>
                                <img src="imgs/a3.png" />
                            </div>
                            <div class="col-md-12 user-details">
                                <span>Mrs. Ranjeet Tiwari</span>
                                <span>New Delhi</span>
                                <span>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                            </div>
                            <div className='col-md-12'>
                                <hr></hr>
                            </div>
                            <div className='col-md-12'>
                                <p class="content">
                                    <i class="fas fa-quote-left"></i>
                                    From signing the deal to design approvals to payments etc.
                                    till date everything has gone smoothly & tension free for me.
                                    It feels like family & we feel safe & secured in their hands.
                                    <i class="fas fa-quote-right"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                    <div className='testimonials-container box-shadow common-padding'>
                        <div className='row mb-3'>
                            <div className='col-md-12  text-center'>
                                <img src="imgs/a4.png" />
                            </div>
                            <div class="col-md-12 user-details">
                                <span>
                                    Mrs. Mohit Makkar</span>
                                <span>New Delhi</span>
                                <span>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </span>
                            </div>
                            <div className='col-md-12'>
                                <hr></hr>
                            </div>
                            <div className='col-md-12'>
                                <p class="content">
                                    <i class="fas fa-quote-left"></i>
                                    Fantastic company! They are knowledgeable about all of the products
                                    I have needed from them. They will take care of everything you need from the small details to the big ones!
                                    <i class="fas fa-quote-right"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </>
}