import './ProjectCounter.css';

export const ProjectCounter = () => {
    return <>
        <div className='container project-counter display-Card mt-5 box-shadow common-padding'>
            <div className='row'>
                <div className='col-md-3 text-center right-border'>
                    <h2 className='count'>600</h2>
                    <h2>Projects</h2>
                </div>
                <div className='col-md-3 text-center right-border'>
                    <h2 className='count'>529</h2>
                    <h2>Clients</h2>
                </div>
                <div className='col-md-3 text-center right-border'>
                    <h2 className='count'>888</h2>
                    <h2>Success</h2>
                </div>
                <div className='col-md-3 text-center'>
                    <h2 className='count'>20</h2>
                    <h2>Upcoming Projects</h2>
                </div>
            </div>
        </div>
    </>
}