
import './Video.css';

export const VideoBanner = () => {
    return <>
        <div className="video-banner">
            <div className="video-bg">
                <img src="imgs/banner/banner-1.jpg" alt="" />
            </div>
            <div className="video-container">
                <div className="video">
                    <video muted="" autoPlay="autoplay" poster="imgs/banner/banner-1.jpg" loop="" playsInline="">
                        <source src="imgs/banner/banner-video.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <h1>
                Put us in your trust we are better than the rest
            </h1>
        </div>
    </>
}

