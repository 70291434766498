import './navigation.css';

export const Navigation = () => {

    return <>
        <header className="header_area sticky-header">
            <nav className="app-nav navbar navbar-expand-lg navbar-light blur-bg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="imgs/logo.png" alt="" height="90" width="120" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="full-width">
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="header-contact-details">
                                <li>
                                    <p>
                                        <i className="fa fa-building" aria-hidden="true"></i>
                                        Mon - Fri : 8:00 - 9:00
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i className="fa-solid fa-phone"></i>
                                        +91-98-10-016-683 | +91-98-10-016-653
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i className="fa  fa-envelope"></i>
                                        info@gurujiinfratech.com
                                    </p>
                                </li>
                            </ul>
                            <div className="break"></div>
                            <ul className="navbar-nav">
                                <li className="nav-item submenu dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Projects
                                    </a>
                                    <ul className="dropdown-menu blur-bg" aria-labelledby="navbarDropdownMenuLink">
                                        <li className="nav-item"><a className="nav-link" href="#">Delhi</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#">Gurugram</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#">Mumbai</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#">Pune</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#">Bangalore</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#">Greater Noida</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#">Ghaziabad</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><a className="nav-link" href="#">Services</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">About</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Contact</a></li>
                                <li className="nav-item">
                                    <a className="nav-link header-nav-btn" href="#" data-bs-toggle="modal"
                                        data-bs-target="#sellYourProperty">
                                        Sale Your Property
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </>
}