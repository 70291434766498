import './FlyOut.css';
import { Helmet } from "react-helmet";

export const FlyOut = () => {

    return <>
        <div className="flyOut">
            <ul>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Search Property">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#searchProperty">
                        <i className="fa fa-search" aria-hidden="true"></i></a>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Sell your property">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#sellYourProperty">
                        <i className="fa fa-plus" aria-hidden="true"></i></a>
                </li>
                <li>
                    <a href="#"> <i className="fa fa-phone"></i> </a>
                    <span className="flyOut-item">+91-98-10-016-683,<br /> +91-98-10-016-653 </span>
                </li>
                <li>
                    <a href="#">
                        <i className="fa fa-envelope"></i>
                    </a>
                    <span className="flyOut-item"> info@gurujiinfratech.com</span>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook">
                    <a href="https://www.facebook.com/people/Guruji-Infratech-LLP/100092143789103/" target="_blank">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Instagram">
                    <a href="https://www.instagram.com/gurujiinfratechllp/?igshid=YmMyMTA2M2Y%3D" target="_blank">
                        <i className="fab fa-instagram"></i>
                    </a>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Pinterest">
                    <a href="https://in.pinterest.com/pin/guru-ji-welfare-in-dwarka-mor-price-reviews-floor-plan-in-2023--993677105260963358/"
                        target="_blank">
                        <i className="fab fa-pinterest-p"></i>
                    </a>
                </li>
                <li data-bs-toggle="tooltip" data-bs-placement="top" title="YouTube">
                    <a href="https://www.youtube.com/@gurujiinfratechllp7955" target="_blank">
                        <i className="fab fa-youtube" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>
        </div>

        <div className="modal fade" id="sellYourProperty" tabIndex="-1" aria-labelledby="sellYourProperty" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="sellYourProperty">Propery Details</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input type="text" className="form-control" id="name" aria-describedby="name"></input>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="email" className="form-control" id="email"></input>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="contact" className="form-label">Contact</label>
                                <input type="text" className="form-control" id="contact"></input>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="propertyType" className="form-label">&nbsp;</label>

                                <select className="form-control" id="propertyType">
                                    <option>Property Type</option>
                                    <option>Flat</option>
                                    <option>Villa</option>
                                </select>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="propertyDetails" className="form-label">Property Details or Address</label>
                                <textarea id="propertyDetails" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="searchProperty" tabIndex="-1" aria-labelledby="searchProperty" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchProperty">Search Property</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-5">

                                <select className="form-control" id="location">
                                    <option>Location</option>
                                    <option>New Delhi </option>
                                    <option>Gurugram</option>
                                    <option>Mumbai</option>
                                    <option>Pune</option>
                                    <option>Greater Noida</option>
                                    <option>Ghaziabad</option>
                                </select>
                            </div>
                            <div className="col-md-5">

                                <select className="form-control" id="propertyType">
                                    <option>Property Type</option>
                                    <option>Flat</option>
                                    <option>Villa</option>
                                </select>
                            </div>
                            <div className="col-md-2 text-center">
                                <a href="#" id="search" className="btn btn-primary">Search
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div className="row mb-3 mt-3">
                            <div className="col-md-12 or-container">
                                <span className="or">OR</span>
                                <span className="diveder"></span>
                            </div>
                            <div className="col-md-12"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <a href="#" id="search" className="btn btn-primary">Explore Properties
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <Helmet>
            <script src="./FlyOut.js" type="text/javascript" />
        </Helmet> */}

    </>
}