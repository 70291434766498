import { useEffect } from 'react';
import './PhotoGallery.css'
import 'baguettebox.js/dist/baguetteBox.min.css';
import baguetteBox from 'baguettebox.js';

export const PhotoGalleryGallery = () => {
    useEffect(() => {

        baguetteBox.run('.compact-gallery', { animation: 'slideIn' });

    })
    return <>
        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <h2>Photo Gallery</h2>
                </div>
            </div>
            <div className="row gallery-block compact-gallery mt-3">
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/projects/p1.jpg">
                        <img className="img-fluid image" src="imgs/projects/p1.jpg" />
                    </a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/b1.jpg">
                        <img className="img-fluid image" src="imgs/b1.jpg" />
                    </a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/b2.jpg">
                        <img className="img-fluid image" src="imgs/b2.jpg" />
                    </a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/b3.jpg">
                        <img className="img-fluid image" src="imgs/b3.jpg" />
                    </a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/b4.jpg">
                        <img className="img-fluid image" src="imgs/b4.jpg" />
                    </a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/b5.jpg">
                        <img className="img-fluid image" src="imgs/b5.jpg" />
                    </a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/k1.jpg">
                        <img className="img-fluid image" src="imgs/k1.jpg" />
                    </a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/k2.jpg">
                        <img className="img-fluid image" src="imgs/k2.jpg" />
                    </a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/k3.webp">
                        <img className="img-fluid image" src="imgs/k3.webp" />
                    </a>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6  item zoom-on-hover">
                    <a className="lightbox" href="imgs/k4.jpg">
                        <img className="img-fluid image" src="imgs/k4.jpg" />
                    </a>
                </div>
            </div>
        </div>
    </>
}