import './Footer.css';

export const Footer = () => {
    return <>
        <div className="container-fluid mt-5 footer">
            <footer className="text-center text-lg-start text-dark">
                <section>
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Guruji Infratech</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                                <p>
                                    Thank you for considering Guruji Builders for your real estate needs.
                                    <br /><br />We look forward to
                                    helping you achieve your dreams of owning a beautiful and comfortable home.
                                </p>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Projects</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto"
                                />
                                <p>
                                    <a href="#!" className="text-dark">Guruji Swarna Vihar -1</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-dark">Gaur Green City</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-dark">Metrotech Builder Floors</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-dark">DDA Golf View Apartments</a>
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Information</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                                <p>
                                    <a href="#!" className="text-dark">About Us</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-dark">Our Services</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-dark">Our Projects</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-dark">Contact Us</a>
                                </p>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold">Contact</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                                <p><i className="fas fa-home mr-3"></i> B-49/A, Jain Rd. Bhagwati Garden Extension, Dwarka Mor,
                                    Uttam Nagar, New Delhi,- 110059</p>
                                <p><i className="fas fa-envelope mr-3"></i> info@gurujiinfratech.com</p>
                                <p><i className="fas fa-phone mr-3"></i> +91-98-10-016-683, +91-98-10-016-653 </p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center p-3 copyright">
                    © 2023 Copyright:
                    <a className="text-dark" href="https://www.gurujiinfratech.com/">Guruji Infratech</a>
                </div>
            </footer>
        </div>
    </>
}