import { VideoBanner } from '../Components/Banner/Video';
import { WhyUs } from '../Components/WhyUs';
import { OurServices } from '../Components/OurServices';
import { ProjectCounter } from '../Components/ProjectCounter';
import { OurProjects } from '../Components/OurProjects';
import { Testimonials } from '../Components/Testimonials';
import { VideoGallery } from '../Components/VideoGallery';
import '../Style/home.css';

export const Home = () => {
    return <>
        <VideoBanner />
        <WhyUs />
        <OurServices />
        <ProjectCounter/>
        <OurProjects/>
        <Testimonials/>
        <VideoGallery/>
    </>
}