import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Navigation } from './Components/Navigation';
import { FlyOut } from './Components/Flyout';
import { Footer } from './Components/Footer';

import { Home } from './Pages/home';

import './App.css';
import { Projects } from './Pages/projects';
import { Property } from './Pages/property';

function App() {
  return <>
    <Router>
      <Navigation />
      <FlyOut />

      <Routes>
        <Route exact path='/' element={<Home />}></Route>
        <Route exact path='/Projects' element={<Projects />}></Route>
        <Route exact path='/Property' element={<Property />}></Route>
      </Routes>
      <Footer />
    </Router>

  </>
}

export default App;
